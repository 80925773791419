<template>
    <div class="replaceCon">
        <div class="holderPicBox">
            <div class="photoBox" @click.stop="goDetail(item.id)" v-for="(item,index) in listData" :key="index">
                <div class="photoCircle">
                    <div class="photoCenter"><img
                            :src="item.url" /></div>
                </div>
                <div class="hiderName">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { APPID, HOST } from '@/utils/api/config'
export default {
    data() {
        return {
            listData: [
            //     {
            //     name: '方雨',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/85b17eac80ed7e3a7223c1505dea16c2.png'
            // }, {
            //     name: '飞扬',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/61fc42424e22346d782a256cab4d19a7.png'
            // }, {
            //     name: '方亮',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/ae99c63f7cf3c827fedce26cc2e702b9.png'
            // }, {
            //     name: '维萌',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/47336e0783b9ab6db30a35f072e2a2e0.png'
            // }, {
            //     name: '方楠',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/4a8315bb3df45da708271a80f9b22ce4.png'
            // }, {
            //     name: '王萍',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/2244ea08024e026d217543aff88182c0.jpeg'
            // }, {
            //     name: '杨宸',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/1808835963f20e33b99d0da2b24f0125.png'
            // }, {
            //     name: '子阁',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/220c0bd04f06d35229384a6df23027db.jpeg'
            // }, {
            //     name: '潇磊',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/e60779660689d89ded09e9355479a919.png'
            // }, {
            //     name: '思宁',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/697b57251ea66a9d82226bb074f72590.jpeg'
            // }, {
            //     name: '柳叶',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/996565d283779d07bced98bdb06f69fb.png'
            // }, {
            //     name: '楚河',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/81e7d9cddbae143288bf4411e2b65fea.jpeg'
            // }, {
            //     name: '雪莲',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/ad9f4aef457e2d2887a01f5e537547b0.jpeg'
            // }, {
            //     name: '崔麒',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/5b587e65d2e2070b2c15134d04c39289.png'
            // }, {
            //     name: '天心',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/f7c020a95df4a94e42e1dc64e3af5ae1.png'
            // }, {
            //     name: '一零',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/485497f3eab0669540964b70d9d022a2.png'
            // }, {
            //     name: '贾如',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/90f4df49242c7cdeadcb69c1bb1eb2cb.png'
            // }, {
            //     name: '小蔡',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/2a1ec77a84dfcc3f8adb2a318afc3f6c.png'
            // }, {
            //     name: '维琳',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/08d2b73b61b2aec196521530ee2e8389.png'
            // }, {
            //     name: '星霖',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/d4b52d2f1a116821a2c49a8916503ab5.jpeg'
            // }, {
            //     name: '狄可',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/b26a89c352281f1b5d082054bd345ff0.png'
            // }, {
            //     name: '董岩',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/bc08c44fa1d13e9a7a58f9d33aa67701.jpeg'
            // }, {
            //     name: '洪放',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/3e3c6bb245858b4a472eb41a5ab7f32c.jpeg'
            // }, {
            //     name: '王鹏',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/7fcd544fb819d9e94e0a766e8aafe1cd.jpeg'
            // }, {
            //     name: '楚楚',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/94df67d54089eaca61f06d900dff7137.jpeg'
            // }, {
            //     name: '魏巍',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/2dff481ccca889cbeac3fb5eb292a11b.jpeg'
            // }, {
            //     name: '马悦',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/6bd436c1210b15effb7617b764d96600.jpeg'
            // }, {
            //     name: '佳玉',
            //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/5377af6789cb62a0b5c6b2c1d08a98ee.jpeg'
            // }
            ],
        }
    },
    methods:{
        goDetail(id){
            // this.$router.push({path:'/hosterDetail/'+id})
            let { href } = this.$router.resolve({ path: '/hosterDetail/' + id })
            window.open(href, '_blank')
        },
        //获取列表
        getList(){
            this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${HOST}&page=1&limit=1000`).then(res => {
                if(res.code!=='200'){
                    this.$message.error(res.msg)
                    return
                }
                this.listData = (res.data.list || []).map(v => {
                    let path = v?.cover[0]?.path || ''
                    return {
                        name: v.title,
                        url: path,
                        id: v.id
                    }
                });
            })
        },
    },
    created(){
        this.getList()
    }
}
</script>

<style lang="scss" scoped>
.holderPicBox{
    margin-top:80px;
    width:100%;
    min-height:600px;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
}
.photoBox{
    margin:0px 35px ;
    margin-bottom: 40px;
}
.photoCircle{
    width:168px;
    height:168px;
    border-radius:168px;
    border:1px solid #eee;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}
.hiderName{
    width:100%;
    text-align: center;
    font-size: 18px;
    color:black;
    margin-top:15px;
}
.photoCenter{
    width:138px;
    height:138px;
    border-radius:138px;
    border:1px solid #eee;
    background:darkgrey;
    
}
.photoCenter img{
    width:100%;
    height:100%;
    border-radius:140px;
}
.photoCircle:hover{
    background: rgb(255, 143, 143); 
}
</style>